import React from 'react'
import Layout from '../components/layout'
import Project from '../components/project'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const ProjectPage = ({data}) => {
    return (
        <Layout currentPage="project">
            <SEO
              title='Project'
            />
            <Project projects={data.allContentfulProject.edges}/>
        </Layout>
    )
}

export const data = graphql`
  query{
    allContentfulProject{
      edges{
        node{
          title
          date
          heroImage{
            gatsbyImageData
          }
          projectLink
          body {
            raw
          }
        }
      }
    }
  }
`;

export default ProjectPage
