import React, { useEffect, useState } from 'react'
import {mainProjectWrapper,projectWrapper,projectImage,projectTitle,projectSubtitle,show} from './project.module.css'
import Gap from './gap'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {BLOCKS} from '@contentful/rich-text-types'

const Project = ({projects}) => {

    const [isShowProject,setIsShowProject] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setIsShowProject(true)
        },500)
    },[]);

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node,children) => {
                return <p className={projectSubtitle}>{children}</p>
            }
        }
    }
    return (
        <div className={mainProjectWrapper}>
        {projects.map(project=>{
            var project = project.node;
            const image = getImage(project.heroImage)
            return (
                <a href={project.projectLink} target="__blank">
                    <div className={(isShowProject)?`${projectWrapper} ${show}`:`${projectWrapper}`}>
                        <GatsbyImage class={projectImage} image={image} alt='project-image'/>
                        <Gap height={15}/>
                        <h2 className={projectTitle}>{project.title}</h2>
                        <Gap height={5}/>
                        {renderRichText(project.body,options)}
                    </div>
                </a>
            );
        })}
        </div>
    )
}

export default Project
